
import moment from 'moment';
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';

export default {
  name: 'Calendar',
  components: {
    HotelDatePicker: () =>
      typeof window !== 'undefined' ? import('vue-hotel-datepicker') : null,
  },
  props: {
    rangeSelect: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: Object,
      default: null,
    },
    dateFromText: {
      type: String,
      default: '-',
    },
    dateToText: {
      type: String,
      default: '-',
    },
    minOneNight: {
      type: Boolean,
      default: true,
    },
    startFromDate: {
      type: String,
      default: '',
      required: false,
    },
  },
  data () {
    return {
      isOpen: false,
    };
  },
  computed: {
    lang () {
      return this.$i18n.locale;
    },
    i18n () {
      return this.lang === 'en'
        ? {
          'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
          'check-in': this.dates.dateFrom
            ? moment(this.dates.dateFrom)._i
            : this.dateFromText,
          'check-out': this.dates.dateTo
            ? moment(this.dates.dateTo)._i
            : this.dateToText,
          'month-names': [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          tooltip: {
            minimumRequiredPeriod: '', // to avoid error "Cannot read properties of undefined (reading 'minimumRequiredPeriod')"
          },
        }
        : {
          'day-names': ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
          'check-in': this.dates.dateFrom
            ? moment(this.dates.dateFrom)._i
            : this.dateFromText,
          'check-out': this.dates.dateTo
            ? moment(this.dates.dateTo)._i
            : this.dateToText,
          'month-names': [
            'janvier',
            'février',
            'mars',
            'avril',
            'mai',
            'juin',
            'juillet',
            'août',
            'septembre',
            'octobre',
            'novembre',
            'décembre',
          ],
          tooltip: {
            minimumRequiredPeriod: '', // to avoid error "Cannot read properties of undefined (reading 'minimumRequiredPeriod')"
          },
        };
    },
  },
  methods: {
    /**
     * Emit new start date
     * @param {Date} date
     */
    changeFromDate (date) {
      if (
        new Date(date).getTime() !== new Date(this.dates?.dateFrom).getTime()
      ) {
        this.$emit('change-from-date', date);
      }
    },
    /**
     * Emit new end
     * @param {Date} date
     */
    changeToDate (date) {
      if (new Date(date).getTime() !== new Date(this.dates?.dateTo).getTime()) {
        this.$emit('change-to-date', date);
      }
    },
    /**
     * Create date object (UTC) from received input that can be a moment.js date, a Date object, string in 'yyyy-mm-dd' format or null
     * @returns {Date|null}
     */
    convertToDateObj (date) {
      if (date instanceof Date) {
        return new Date(date.toISOString());
      } else if (moment && moment.isMoment(date)) {
        return date.toDate();
      } else if (typeof date === 'string' && date !== '') {
        return moment(date).toDate();
      } else {
        return null;
      }
    },
    openHandler () {
      if (this.$refs.datepicker.isOpen && this.$refs.datepicker.isDesktop) {
        const picker = document.querySelector('.vhd__datepicker--open');
        picker.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
        this.isOpen = true;
      } else {
        this.closeHandler();
      }
    },
    closeHandler () {
      if (this.isOpen && this.rangeSelect && !this.dates.dateTo) {
        this.$emit('clear-data');
      }
    },
  },
};
